/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import styled from "styled-components";

export const LandingPageWrapper = styled.div`
  background-image: url(${(props: { backgroundImageUrl: string }) =>
    props.backgroundImageUrl});
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10000000000000;
`;

export const LandingPage2Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  // left: 0;
  // top: 0;
  z-index: 1000000000000;
`;

export const Title = styled.h1`
  font-family: KinfolkReg;
  font-size: 70pt;
  text-align: center;
  // margin: 0;
  padding: 0;
  color: ${(props) => props.color};
  z-index: 1;
  line-height: 90%;
  // margin: 75px 0 0 0;
  margin: ${(props) => props.margin};
`;

export const Subtitle = styled.h2`
  font-family: KinfolkReg;
  font-size: 18pt;
  text-align: center;
  color: ${(props) => props.color};
  z-index: 1;
  margin: 8px 0 0 0;
  padding: 0;
`;

export const Button = styled.img`
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: -6%;
  cursor: pointer;
`;
