/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React from "react";
import { Title, Subtitle } from "./styles/landingPage";

interface TitleSubtitleProps {
  color: string;
  margin: string;
}

const LandingPage: React.FC<TitleSubtitleProps> = ({ color, margin }) => {
  return (
    <>
      <Title margin={margin} color={color}>
        ZION
      </Title>
      <Subtitle color={color}>CEMETERY</Subtitle>
    </>
  );
};

export default LandingPage;
