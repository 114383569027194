import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import LandingPageIndex from "./components/pages/Landing";
const Story = React.lazy(() => import("./components/pages/Story"));
// import Story from "./components/pages/Story";

const RouteHandler: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={null}>
            <LandingPageIndex />
          </Suspense>
        }
      />
      <Route
        path="/story"
        element={
          <Suspense fallback={null}>
            <Story />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default RouteHandler;
